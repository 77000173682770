import { makeAutoObservable, runInAction } from 'mobx';
import moment from 'moment';
import queryString from 'query-string';
import { API, APIRoutes } from '@api';
import { bookingStore, routerStore, widgetStore } from '@stores';
import { logEvent, EVENTS_NAMES } from '@utils/analytics';
import { formatDate } from '@utils/formatters';
import routes from '@routes';

const CALENDAR_MIN_WIDTH = 220;
const CALENDAR_DEFAULT_WIDTH = 299;
const CALENDAR_NO_PADDING = 273;
const CALENDAR_DAY_BASE_SIZE = 39;

export class CalendarStore {
  isLoading = false;

  isInitialized = false;

  numberOfMonths = 2;

  error = null;

  // old yacht group id
  yachtId = null;

  yachtIds = null;

  blockedDays = {};

  startDate = null;

  endDate = null;

  blockAfter = null;

  currentFocusedInput = 'startDate';

  firstAvailableMonth = null;

  initialMonthDate = null;

  firstAvailableDate = null;

  monthsAheadToBeFetched = null;

  lastFetchedMonth = null;

  calendarOrientation = 'horizontal';

  calendarDaySize = CALENDAR_DAY_BASE_SIZE;

  monthsNumberFromViewport = null;

  params = {};

  debounce = null;

  wrapperPaddingLeft = null;

  shouldFetchNewCalendar = false;

  constructor() {
    makeAutoObservable(this);
  }

  calculateCalendars = () => {
    let monthsNumberFromViewport;

    try {
      const { clientWidth } = document.body;
      monthsNumberFromViewport = Math.floor(
        clientWidth / CALENDAR_DEFAULT_WIDTH,
      );

      if (!monthsNumberFromViewport) {
        monthsNumberFromViewport = Math.floor(clientWidth / CALENDAR_MIN_WIDTH);
      }

      if (monthsNumberFromViewport === 0) {
        this.error = 'Not enough space to render the widget';
      } else {
        this.error = null;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
    return monthsNumberFromViewport;
  };

  calculateExtraDaySize = monthsNumberFromViewport => {
    let extraDaySize;
    try {
      const { clientWidth } = document.body;

      extraDaySize = Math.floor(
        ((clientWidth - monthsNumberFromViewport * CALENDAR_DEFAULT_WIDTH) /
          monthsNumberFromViewport +
          CALENDAR_NO_PADDING) /
          7,
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
    return extraDaySize;
  };

  calculateCalendarDimensions = () => {
    try {
      const { clientWidth } = document.body;

      const monthsNumberFromViewport = this.calculateCalendars();

      let numberOfMonths = monthsNumberFromViewport;

      if (
        this.params.mno &&
        parseInt(this.params.mno, 10) < monthsNumberFromViewport
      ) {
        numberOfMonths = parseInt(this.params.mno, 10);
      }

      const calculatedDaySize = this.calculateExtraDaySize(numberOfMonths);
      const calendarWrapperWidth =
        numberOfMonths * calculatedDaySize * 7 + 26 * numberOfMonths;
      const wrapperPaddingLeft = (clientWidth - calendarWrapperWidth) / 2;

      runInAction(() => {
        this.wrapperPaddingLeft = wrapperPaddingLeft;
        this.numberOfMonths = numberOfMonths;
        this.calendarDaySize = calculatedDaySize;
        this.monthsAheadToBeFetched = monthsNumberFromViewport + 2;
        this.calendarOrientation = 'horizontal';
        this.monthsNumberFromViewport = monthsNumberFromViewport;
      });

      // set vertical orientation
      // if (this.params.mno && this.params.mno > 1) {
      //   if (parseInt(this.params.w, 10) < size.md) {
      //     runInAction(() => {
      //       const monthsNumber = parseInt(this.params.mno, 10);
      //       this.calendarOrientation = 'vertical';
      //       this.calendarDaySize =
      //         CALENDAR_DAY_BASE_SIZE + Math.floor((clientWidth - 318) / 7);
      //       this.monthsAheadToBeFetched = monthsNumber + 2;
      //       this.monthsNumberFromViewport = monthsNumber;
      //       this.numberOfMonths = monthsNumber;
      //     });
      //   }
      // }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };

  initCalendar = async params => {
    try {
      if (
        !this.isInitialized ||
        widgetStore.reinitialize ||
        this.shouldFetchNewCalendar
      ) {
        runInAction(() => {
          this.params = params;
          widgetStore.isLoading = true;
          this.isLoading = true;

          if (params.yid) {
            // eslint-disable-next-line no-console
            console.debug(
              'WARNING! Using data-yacht-id is deprecated. Please use data-yacht-ids instead',
            );

            this.yachtId = params.yid;
          }

          if (params.yids) {
            this.yachtIds = params.yids.split(',');
          }

          if (params.mno) {
            const numberOfMonths = parseInt(params.mno, 10);
            this.numberOfMonths = numberOfMonths;
          }
          this.monthsAheadToBeFetched = this.numberOfMonths + 2;
        });

        if (widgetStore.isAuth) {
          this.calculateCalendarDimensions();
          let firstMonth;
          let unavailableRanges;
          let lastMonthInRange;

          if (this.yachtId) {
            const {
              data: {
                data: { yachtIds },
              },
            } = await API.get(
              APIRoutes.GET_BLOCKED_DATES_BY_GROUP(
                this.yachtId,
                this.monthsAheadToBeFetched,
              ),
            );

            runInAction(() => {
              this.yachtIds = yachtIds;
            });

            const queryParams = { ...this.params };
            delete queryParams.yid;
            queryParams.yids = this.yachtIds;

            routerStore.replace(
              `${routes.root}?${queryString.stringify(queryParams, {
                arrayFormat: 'comma',
              })}`,
            );
          }
          if (this.yachtIds) {
            const {
              data: { data },
            } = await API.get(
              APIRoutes.GET_BLOCKED_DATES_BY_YACHT_IDS(
                this.yachtIds,
                this.monthsAheadToBeFetched,
              ),
            );

            firstMonth = data.firstMonth;
            unavailableRanges = data.unavailableRanges;
            lastMonthInRange = data.lastMonthInRange;
          }

          runInAction(() => {
            [this.yachtId] = this.yachtIds;
            this.initialMonthDate = moment(firstMonth);
            this.firstAvailableDate = moment(firstMonth);
            this.lastFetchedMonth = moment(lastMonthInRange);
            this.blockedDays = unavailableRanges;
            this.isInitialized = true;
            this.shouldFetchNewCalendar = false;
          });
          logEvent(EVENTS_NAMES.CALENDAR_INITIALIZED, { yacht_id: params.yid });
        } else {
          throw new Error('widget is not authorized');
        }
      }
    } catch (error) {
      console.warn(error); // eslint-disable-line
    } finally {
      runInAction(() => {
        this.isLoading = false;
        this.initialized = true;
        widgetStore.isLoading = false;
      });
    }
  };

  screenResizeHandler = () => {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.calculateCalendarDimensions();
    }, 300);
  };

  fetchBlockedDates = async from => {
    try {
      const shouldFetch = from
        .add(this.monthsAheadToBeFetched - 1, 'M')
        .isAfter(this.lastFetchedMonth, 'M');
      console.log({ shouldFetch, lfm: this.lastFetchedMonth });

      if (shouldFetch) {
        const {
          data: {
            data: { unavailableRanges, lastMonthInRange },
          },
        } = await API.get(
          APIRoutes.GET_BLOCKED_DATES_BY_YACHT_IDS(
            this.yachtIds,
            this.monthsAheadToBeFetched,
            formatDate(this.lastFetchedMonth),
          ),
        );

        runInAction(() => {
          this.lastFetchedMonth = moment(lastMonthInRange);
          this.blockedDays = {
            ...this.blockedDays,
            ...unavailableRanges,
          };
        });
      }
    } catch (error) {
      console.warn(error); // eslint-disable-line
    }
  };

  selectDate = (type, date, blockAfter = false) => {
    runInAction(() => {
      this[type] = date;
      const dayAfterSelected = formatDate(moment(date).add(1, 'day'));
      const ifNextDayIsLastBlocked = this.blockedDays[dayAfterSelected] === 'L';

      if (blockAfter) {
        const blockedDaysKeys = Object.keys(this.blockedDays);
        const nearestBlockedDay = blockedDaysKeys.find(b => {
          return moment(b).isAfter(date, 'day');
        });
        if (nearestBlockedDay) {
          const blockFrom = ifNextDayIsLastBlocked
            ? moment(nearestBlockedDay)
            : moment(nearestBlockedDay).add(1, 'day');
          this.blockAfter = formatDate(blockFrom);
        }
      } else {
        this.blockAfter = null;
      }
    });
  };

  onDatesChangeHandler = ({ startDate, endDate }) => {
    if (this.currentFocusedInput === 'startDate') {
      this.selectDate('endDate', null);
    }
    if (startDate) {
      this.selectDate('startDate', startDate, true);
    }
    if (endDate && this.currentFocusedInput !== 'startDate') {
      this.selectDate('endDate', endDate);
    }
  };

  submitCalendar = () => {
    widgetStore.isLoading = true;
    routerStore.push(`${routes.summary}${routerStore.location.search}`);
    logEvent(EVENTS_NAMES.CALENDAR_SUBMITTED, {
      from_date: formatDate(this.startDate),
      to_date: formatDate(this.endDate),
      yacht_id: this.yachtId,
    });
  };

  setCurrentFocusedInput = focusedInput => {
    try {
      runInAction(() => {
        let currentFocusedInput;
        if (!focusedInput) {
          // this.submitCalendar();
          currentFocusedInput = 'startDate';
        } else {
          currentFocusedInput = focusedInput;
        }
        this.currentFocusedInput = currentFocusedInput;
      });
    } catch (error) {
      console.warn(error); // eslint-disable-line
    }
  };

  setInitialMonth = date => {
    this.initialMonthDate = moment(date);
  };

  onNextMonthClickHandler = async date => {
    try {
      runInAction(() => {
        this.setInitialMonth(date);
      });
      await this.fetchBlockedDates(date);
    } catch (error) {
      console.warn(error); // eslint-disable-line
    }
  };

  reset = () => {
    this.startDate = null;
    this.endDate = null;
    this.blockAfter = null;
    this.isInitialized = false;
    this.params = {};
  };

  resetDates = () => {
    this.startDate = null;
    this.endDate = null;
    this.currentFocusedInput = 'startDate';
    this.blockAfter = null;
  };

  redirectToFreshCalendar = () => {
    this.shouldFetchNewCalendar = true;
    this.yachtId = null;
    bookingStore.error = null;
    this.resetDates();

    routerStore.push(`${routes.root}${routerStore.location.search}`);
  };
}

export default new CalendarStore();
